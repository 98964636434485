import React, { useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";

// import Kangaroo from "./../assets/images/kangaroo.png";

function BannerComponent({ banner, companyName, title, subTitle, button }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Reinitialize the carousel when component mounts
    const carouselElement = document.getElementById("myCarousel");
    if (carouselElement) {
      new window.bootstrap.Carousel(carouselElement, {
        interval: 3000, // 3 seconds
        ride: "carousel",
      });
    }
  }, [banner]);

  return (
    <>
      <section className="banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-lg-8 order-md-last">
              <div
                id="myCarousel"
                className="custom-carousel-wrapper carousel slide carousel-fade"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  {banner.map((_, index) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current="true"
                        aria-label={`Slide ${index}`}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {banner.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className={`carousel-item item ${
                          index === 0 ? "active" : ""
                        }`}
                      >
                        <img src={el} alt={el} className="d-block w-100" />
                      </div>
                    );
                  })}
                </div>

                <button
                  className="carousel-control-prev d-md-none"
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next d-md-none"
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 order-md-first">
              <div className="banner-txt">
                {companyName && (
                  <h2
                    className="animated fadeInRight"
                    style={{ fontSize: 27, letterSpacing: 2 }}
                  >
                    {companyName}
                  </h2>
                )}
                <h2
                  className="animated fadeInRight"
                  style={{ fontSize: companyName ? 24 : "", letterSpacing: 2 }}
                >
                  {title}
                </h2>
                <p style={{ fontSize: companyName ? 18 : "" }}>{subTitle}</p>
                {button?.link && (
                  <Link to={button?.link} className="book-now">
                    {button?.title}
                  </Link>
                )}
                {!button?.link && (
                  <div
                    className="book-now cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <img src={Kangaroo} className="maskot-header" alt={Kangaroo} /> */}
      </section>
    </>
  );
}

export default BannerComponent;
