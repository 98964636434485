const URL = `${process.env.REACT_APP_SITE_URL}/tutorial_videos`;
const AppConstants = {
  routes: {
    home: "/",
    booking: "/booking",
    bookingSearch: "/booking/search",
    dinning: "/dining",
    dinningSearch: "/dining/search",
    nightlifelanding: "/night-life",
    register: "/signup",
    login: "/login",
    companyType: "/company-type",
    machineConfiguration: "/machine-configuration",
    tutorial: "/tutorial",
    advertising: "/advertising",
    contact: "/contact",
    mainHome: "/main-home",
  
    tour: {
      home: "/tour",
      createCompany: "/tour/create-company",
      onboardStatus: "/tour/stripe-onboard-status",
      bookingCheckout: "/tour/booking-checkout",
      bookingSuccess: "/tour/booking-success",
      tourBookingCalendar: "/tour/booking-calendar",
      tourRezdyIntegration: "/tour/rezdy-integration",
      tourCostPersion: "/tour/cost-person",
      tourCompanyCategory: "/tour/company-category",
      tourCompanyDetails: "/tour/company-details",
      tourStops: "/tour/stops",
      tourLocatoinDetails: "/tour/location-details",
      tourPayments: "/tour/payment",
    },
    admin: {
      home: "/admin",
      dashboard: "/admin/dashboard",
      setting: "/admin/settings",
      updateActivityPrice: "/admin/update-cost-person",
      updateDetails: "/admin/update-details",
      changeEmail: "/admin/change-email",
      changePassword: "/admin/change-password",
      createAds: "/admin/create-ads",
      listAds: "/admin/ads",
      selectScreen: "/admin/select-screen",
      uploadContent: "/admin/upload-content",
      adsSubscription: "/admin/ads-subscription",
      adsReports: "/admin/ads-reports",
      // RESTAURANT
      restaurantEditListing: "/admin/restaurant-edit-listing",
      updateRestaurantCategory: "/admin/restaurant-category",
      updateRestaurantMenu: "/admin/restaurant-menu",
      updateRestaurantDetails: "/admin/restaurant-details",
      // updateDesireServices: "/admin/desire-services",
      breakfastMenu: "/admin/breakfast-menu",
      lunchMenu: "/admin/lunch-menu",
      dinnerMenu: "/admin/dinner-menu",
      // END RESTAURANT
      
      // NIGHTLIFE
      nightlifeEditListing: "/admin/nightlife-edit-listing",
      nightlifeUpdateCategory: "/admin/nightlife-category",
      nightlifeUpdateVanueDetails: "/admin/nightlife-venue-details",
      updateMenuDetails: "/admin/menu-details",
      updateVenueDtails: "/admin/venue-details",
      eventList: "/admin/events",
      updateEventDtails: "/admin/event-details",
      updateCostPerson: "/admin/cost-person",
      // USER ADMIN - ACTIVITY
      activityEditListing: "/admin/edit-listing",
      activityCompanyType: "/admin/company-type",
      actiityCreateCompany: "/admin/create-company",
      updateCompanyDetails: "/admin/company-details",
      updateStops: "/admin/stops",
      updateLocationDetails: "/admin/location-details",
      addMachine: "/admin/add-machine",
      updateMachine: "/admin/update-machine",
      listMachine: "/admin/machines",
      archivedMachine: "/admin/archived-machines",
      onboardStatus: "/admin/stripe-onboard-status",
      // CHECKINGS
      activityList: "/admin/activity-list",
      nightLifeList: "/admin/nightlife-list",
      restaurantList: "/admin/restaurant-list",
      tags: "/admin/tags",
      nightlifeEvents: "/admin/nightlife-events",
      planSubscription: "/admin/plan",
      addEvents: "/admin/add-events",
      updateEvents: "/admin/update-events",
      liveEvents: "/admin/live-events",
      archivedEvents: "/admin/archived-events",
      users: "/admin/users",
      userAdd: "/admin/user-add",
      userUpdate: "/admin/update-add",
      adminUpdateCostPerson: "/admin/cost-persons",
      adminUpdateCompanyDetails: "/admin/update-company-details",
      adminUpdateStopDetails: "/admin/update-stop-details",
      adminUpdateLocationDetails: "/admin/update-location-details",
      adminUpdateCompanyCategory: "/admin/update-company-category",
      // designer upload content
      desigUploadContent: "/admin/ads-content-list",
    },
    restaurant: {
      desiredServices: "/restaurant/desired-services",
      restaurantCategory: "/restaurant/categories",
      restaurantDetails: "/restaurant/details",
      restaurantMenu: "/restaurant/menu",
      newRestaurantOption: "/restaurant/rest-options",
      restaurantPayment: "/restaurant/billings",

      home: "/restaurant",
      restaurantCreate: "/restaurant/create-company",
      breakfastMenu: "/restaurant/breackfast-menu",
      lunchMenu: "/restaurant/lunch-menu",
      dinnerMenu: "/restaurant/dinner-menu",
      menuPreview: "/restaurant/menu-preview",
    },
    nightLife: {
      home: "/nightlife",
      category: "/nightlife/category",
      vanueDetails: "/nightlife/venue-details",
      eventDetails: "/nightlife/event-details",
      payments: "/nightlife/payment",
      nightLife: "/nightlife",
      nightLifeEventDetails: "/nightlife/eventDetails",
      newNightlifeOption: "/nightlife/nightlife-options",
    },
  },
  tutorial: {
    // Tutorial component data
    activitySignup: `${URL}/activity-signup.mp4`,
    agentVideo: `${URL}/agent-video.mp4`,
    paymentSetup: `${URL}/payment-setup.mp4`,
    // activity user tutorial
    dashboard: `${URL}/dashboard.mp4`,
    editListing: `${URL}/edit-listing.mp4`,
    costPerPerson: `${URL}/cost-per-person-signup.mp4`,
    companyDetails: `${URL}/company-details.mp4`,
    locationDetails: `${URL}/location-details.mp4`,
    runAds: `${URL}/run-ad.mp4`,
    selectScreen: `${URL}/select-screen.mp4`, // not available
    uploadContent: `${URL}/upload-content.mp4`,
    settings: `${URL}/settings.mp4`,
    pricePerPerson: `${URL}/price-per-person.mp4`,
    categorySelection: `${URL}/category-selection.mp4`,
    activityCompanyType: `${URL}/activity-company-type3.mp4`,
    activityCompanyType5: `${URL}/activity-company-type5.mp4`,
    adsList: `${URL}/ads-list.mp4`, // not available
    stopVideos: `${URL}/stop-tutorial-video.mp4`, // not available
    rezdyIntegrationVideo: `${URL}/rezdy-integration-video.mp4`,
    resovaIntegrationVideo: `${URL}/resova-integration-video.mp4`,
  },
};

export default AppConstants;
