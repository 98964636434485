import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { State } from "country-state-city";
import webServices from "../../services/webServices";
import checkIcon from "./../../assets/images/admin/check-icon.png";
import ImageUploads from "../util/ImageUploads";
import BannerImagesUploads from "../util/BannerImagesUploads";
import { handleNumericInput } from "../util/utils";

const DAYS = {
  Monday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Tuesday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Wednesday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Thursday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Friday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Saturday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Sunday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid var(--theme-dark1) !important;",
    color: "var(--black)",
    height: 40,
    fontSize: 16,
    borderRadius: "var(--bs-border-radius)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const initExclusions = [
  { id: 1, value: "" },
  { id: 2, value: "" },
  { id: 3, value: "" },
  { id: 4, value: "" },
];

function NightLifeVenueDetailsComp({
  onSubmit,
  loading,
  details,
  heading,
  Sidebar,
  buttons,
}) {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [profileImage, setProfileImage] = useState("");
  const [bannerImages, setBannerImages] = useState(details?.banners_path || []);
  const [isBannerImageChange, setIsBannerImageChange] = useState(false);
  const [isProfileImageChange, setIsProfileImageChange] = useState(false);
  const [isBackgroundImageChange, setIsBackgroundImageChange] = useState(false);
  const [background, setBackground] = useState([]);
  const [states, setStates] = useState([]);
  const [backgroundImgPreview, setBackgroundImgPreview] = useState("");

  const [highlights, setHighlights] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
    { id: 6, value: "" },
  ]);
 
  const [exclusions, setExclusions] = useState(initExclusions);
  const [values, setValues] = useState({
    name: details?.name || "",
    phone: details?.phone || "",
    address: {
      streetName: details?.address?.streetName || "",
      city: details?.address?.city || "",
      state: details?.address?.state || "",
      zipCode: details?.address?.zipCode || "",
    },
    highlights: [],
    exclusions: [],
    overview: details?.overview || "",
    profileImage: "",
    bannerImage: "",
    openHours: DAYS,
    offerText: details?.offer_text || "",
    offerText2: details?.offer_text2 || "",
    backgroundImage: "",
    backgroundImageOther: "",
    isBackgroundImageUpload: false,
    isMenuAvailable: details?.is_menu_available || "",
  });

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getNightLifeBackground();
      if (resp.type === "success") {
        const data = resp.payload[0];
        setBackground(resp.payload);
        setBackgroundImgPreview(details?.bg_image_path || data.image_path);
        setValues({
          ...values,
          backgroundImage: details?.background_images || data.image,
        });
      }
    }
    fetchData();

    const data = State.getStatesOfCountry("AU");
    const options = data.map((s) => ({
      value: s.isoCode,
      label: s.name,
    }));
    setStates(options);
  }, []);

  useEffect(() => {
    // setHighlights(details?.highlights || highlights);
    // setExclusions(details?.exclusions || initExclusions);
    setProfileImage(details?.image_path || "");
    setBannerImages(details?.banners_path);
    setBackgroundImgPreview(details?.bg_image_path || "");
  }, [details]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name === "phone" && value.length > 10) {
      setValues({ ...values, phone: value.slice(0, 10) });
    }
  };

  const handleChangeHighlight = (id, value) => {
    setHighlights(
      highlights.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeExclusion = (id, value) => {
    setExclusions(
      exclusions.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeHours = (day, field, value) => {
    setValues({
      ...values,
      openHours: {
        ...values.openHours,
        [day]: {
          ...values.openHours[day],
          [field]: value,
        },
      },
    });
  };

  const handleSubmit = async (val) => {
    if (validator.current.allValid()) {
      values.highlights = highlights;
      values.exclusions = exclusions;
      values.profileImage = profileImage;
      values.backgroundImageOther = isBackgroundImageChange
        ? backgroundImgPreview
        : "";
      values.profileImage = isProfileImageChange ? profileImage : "";
      values.bannerImage = isBannerImageChange ? bannerImages : "";

      onSubmit(values, val);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeBackground = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = background.find(
      (option) => option.image === selectedValue
    );
    setBackgroundImgPreview(selectedObject.image_path);
    setValues({ ...values, backgroundImage: selectedObject.image });
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phone: inputValue,
      });
    }
  };

  const handleChangeState = (selected) => {
    setValues({ ...values, address: { ...values.address, state: selected } });
  };

  const handleCheckMenu = (value) => {
    setValues({ ...values, isMenuAvailable: value });
  };

  const getProfileImage = (img) => {
    setProfileImage(img);
    setIsProfileImageChange(true);
  };

  const getBackgroundImage = (img) => {
    setIsBackgroundImageChange(true);
    setBackgroundImgPreview(img);
  };

  const getBannerImages = (banners) => {
    setBannerImages(banners);
    setIsBannerImageChange(true);
    setValues({ ...values, bannerImageUploaded: "uploaded" });
  };

  return (
    <>
      <div className="restaurant-details-page venue-details-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <Sidebar details={details} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">{heading}</div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will appear on your venue listing
                  </div>
                  <div className="custom-field-wrapperr">
                    <div className="row">
                      <div className="col-12">
                        <div className="main-heading2 mb-3">Venue Details:</div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-3">
                          <label htmlFor="VenueName" className="fs-md-18 mb-1">
                            Venue Name:<span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="VenueName"
                            placeholder=""
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                          {validator.current.message(
                            "venue name",
                            values.name,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-3">
                          <label htmlFor="VenuePhone" className="fs-md-18 mb-1">
                            Venue Phone:<span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="VenuePhone"
                            placeholder=""
                            autoComplete="off"
                            name="phone"
                            maxlength={10}
                            value={values.phone}
                            onChange={handleChangePhone}
                          />
                          {validator.current.message(
                            "venue phone",
                            values.phone,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>

                    {/* venue Address */}
                    <div className="row">
                      <div className="col-12">
                        <div className="location-details-page info-cover mb-2 pb-lg-3">
                          <label className="fs-md-18 mb-1">
                            Venue Address :
                          </label>
                          <div className="address-wrapper">
                            <div className="row gx-3">
                              <div className="col-12">
                                <div className="form-group">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="address1"
                                  >
                                    Address <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="address1"
                                    placeholder=""
                                    autoComplete="off"
                                    name="address.streetName"
                                    value={values.address.streetName}
                                    onChange={handleChange}
                                  />
                                  {validator.current.message(
                                    "address",
                                    values.address.streetName,
                                    "required"
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="city"
                                  >
                                    City <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    placeholder=""
                                    autoComplete="off"
                                    name="address.city"
                                    value={values.address.city}
                                    onChange={handleChange}
                                  />
                                  {validator.current.message(
                                    "city",
                                    values.address.city,
                                    "required"
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="State"
                                  >
                                    State <span className="text-red">*</span>
                                  </label>
                                  <Select
                                    className="text-body"
                                    styles={customStyles}
                                    placeholder="Select State"
                                    value={values?.address?.state}
                                    name="address.state"
                                    onChange={handleChangeState}
                                    options={states}
                                  />
                                  {validator.current.message(
                                    "state",
                                    values.address.state,
                                    "required"
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group mb-2">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="zipcode"
                                  >
                                    Post Code{" "}
                                    <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="zipcode"
                                    placeholder=""
                                    autoComplete="off"
                                    maxLength="6"
                                    name="address.zipCode"
                                    value={values.address.zipCode}
                                    onChange={handleChange}
                                  />
                                  {validator.current.message(
                                    "post code",
                                    values.address.zipCode,
                                    "required"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Hours */}
                    <div className="row">
                      <div className="col-12 right-section">
                        <div className="p-3 p-sm-0 mb-4">
                          <div
                            className="info-cover breakfast-timing"
                            style={{ minWidth: "400px" }}
                          >
                            <div className="main-heading2 mb-2">Hours:</div>
                            <div className="title-cover">
                              <div className="row">
                                <div className="col-4 mb-1">&nbsp;</div>
                                <div className="col-8 d-flex gap-3">
                                  <div className="fs-14 fw-semibold flex-fill">
                                    Opening Time
                                  </div>
                                  <div className="fs-14 fw-semibold flex-fill">
                                    Closing Time
                                  </div>
                                </div>
                              </div>
                            </div>
                            {Object.entries(values.openHours).map(
                              ([day, hours], index) => (
                                <div key={index} className="day-cover row">
                                  <div className="col-4 mb-1">{day}:</div>
                                  <div className="col-8 d-flex gap-3">
                                    <div className="flex-fill mb-2">
                                      <input
                                        type="time"
                                        className="form-control"
                                        autoComplete="off"
                                        value={hours.openningTime}
                                        onChange={(e) =>
                                          handleChangeHours(
                                            day,
                                            "openningTime",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex-fill mb-2">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={hours.closingTime}
                                        autoComplete="off"
                                        onChange={(e) =>
                                          handleChangeHours(
                                            day,
                                            "closingTime",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* overview */}
                    <div className="info-cover mb-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Overview:
                      </div>
                      <textarea
                        id="overview"
                        rows="5"
                        className="form-control"
                        placeholder="Please provide a 75-to-150-word descripition of activity."
                        autoComplete="off"
                        name="overview"
                        value={values.overview}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    {/* Highlight */}
                    <div className="info-cover mb-1 mb-lg-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Highlight:
                      </div>
                      <div className="row">
                        {highlights.map((field) => (
                          <div key={field.id} className="col-sm-6">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Hightlight"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeHighlight(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* do you have menu */}
                    <div className="d-flex align-items-center gap-2 mb-1 mb-lg-4 pb-2">
                      <label className="m-0" htmlFor="Recurring">
                        Do you have a menu ?
                      </label>{" "}
                      <input
                        type="radio"
                        value="Yes"
                        className="custom-radio m-0 cursor-pointer"
                        name="menu"
                        checked={values.isMenuAvailable === "Yes"}
                        onChange={(e) => handleCheckMenu(e.target.value)}
                      />
                      <label className="m-0" htmlFor="Recurring">
                        Yes
                      </label>
                      <input
                        type="radio"
                        value="No"
                        className="custom-radio m-0 cursor-pointer"
                        name="menu"
                        checked={values.isMenuAvailable === "No"}
                        onChange={(e) => handleCheckMenu(e.target.value)}
                      />
                      <label className="m-0" htmlFor="Recurring">
                        No
                      </label>
                    </div>

                    {/* exclusions */}
                    {values.isMenuAvailable === "No" && (
                      <div className="info-cover mb-3 mb-lg-4">
                        <div className="main-heading2 text-white mb-2 pb-1">
                          Exclusion:
                        </div>
                        <div className="row">
                          {exclusions.map((field) => (
                            <div key={field.id} className="col-sm-6">
                              <div className="form-group d-flex align-items-center gap-2">
                                <div className="icon">
                                  <img
                                    src={checkIcon}
                                    className="img-responsive"
                                    alt="Check"
                                  />
                                </div>
                                <input
                                  type="text"
                                  className="form-control rounded-pill"
                                  id=""
                                  placeholder="Exclusion"
                                  autoComplete="off"
                                  value={field.value}
                                  onChange={(e) =>
                                    handleChangeExclusion(
                                      field.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Images */}
                    <div className="event-details-page info-cover mb-4">
                      <div className="row">
                        <div className="col-lg-5 mb-4 mb-lg-0">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">Images:</div>
                            <div
                              className="profile-cover"
                              style={{ marginBottom: 40 }}
                            >
                              <div className="main-heading2 text-white mb-1">
                                Profile Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (This is the image shown on the search results
                                  page)
                                </i>
                              </p>
                              <ImageUploads
                                getImage={getProfileImage}
                                uploadedImage={profileImage}
                              />
                            </div>
                            <div className="banner-cover">
                              <BannerImagesUploads
                                bannerImages={getBannerImages}
                                images={bannerImages}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">
                              Drink Offer:
                            </div>
                            <div className="offer-cover mb-4">
                              <div className="main-heading2 text-white mb-1">
                                Offer Text:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Describe your offer below in 5 words or less.
                                  For example Three Free Cocktails)
                                </i>
                              </p>
                              <div className="row custom-row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText"
                                      maxLength={10}
                                      value={values.offerText}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText2"
                                      maxLength={10}
                                      value={values.offerText2}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="banner-cover mb-2">
                              <div className="main-heading2 text-white mb-1">
                                Select Background Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Select the background image from the list
                                  below)
                                </i>
                              </p>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <select
                                      className="form-select rounded-pill"
                                      name="backgroundImage"
                                      onChange={handleChangeBackground}
                                    >
                                      <option value="" hidden="">
                                        Select
                                      </option>
                                      {background.map((el, index) => (
                                        <option
                                          key={index}
                                          value={el.image}
                                          selected={
                                            el.image ===
                                              values.backgroundImage || false
                                          }
                                        >
                                          {el.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span
                                    style={{
                                      marginLeft: "40%",
                                      marginBottom: 10,
                                    }}
                                  >
                                    OR
                                  </span>
                                  <div className="mt-3">
                                    <ImageUploads
                                      getImage={getBackgroundImage}
                                      uploadedImage={backgroundImgPreview}
                                    />
                                  </div>
                                </div>
                                {backgroundImgPreview && (
                                  <div className="col-sm-6">
                                    <div
                                      className="wrapper"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <div className="preview-box rounded-3">
                                        <img
                                          src={backgroundImgPreview}
                                          className="img-fluid border-0 rounded-3"
                                          alt={backgroundImgPreview}
                                        />
                                        <div
                                          className="moon-wrapper position-absolute start-0 top-0 h-100"
                                          style={{
                                            backgroundImage: `url(${require("../../assets/images/gallery/half-moon.png")})`,
                                          }}
                                        >
                                          <div className="rel-text">
                                            <div className="text-truncate">
                                              {values.offerText}
                                            </div>
                                            <div className="text-truncate mb-1">
                                              {values.offerText2}
                                            </div>
                                            <button
                                              type="button"
                                              className="btn btn-custom2 rounded-0"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              Click to Reveal
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center fw-medium mt-2">
                                        <i>Preview</i>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-4">
                      {buttons.map((button) => (
                        <button
                          key={button.id}
                          onClick={handleSubmit}
                          className="btn btn-custom"
                          disabled={loading || false}
                        >
                          {loading ? "Loading..." : button.label}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NightLifeVenueDetailsComp;
