import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NightLifeSidebar from "../layouts/NightLifeSidebar";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";
import NightlifeCategoryComp from "../common/NightlifeCategoryComp";

function Category() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);

  const buttons = [
    {
      id: 1,
      label: "Submit",
    },
  ];

  const handleSubmitForm = async (data) => {
    data.profileLavel =
      companyDetails?.progress < 25
        ? AppConstants.routes.nightLife.category
        : "";
    data.progress = companyDetails?.progress < 25 ? 25 : "";
    setLoading(true);
    const resp = await webServices.createNightLife({
      ...data,
      nightLifeId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      navigate(AppConstants.routes.nightLife.vanueDetails);
    }
  };

  return (
    <>
      <NightlifeCategoryComp
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        Sidebar={NightLifeSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default Category;
