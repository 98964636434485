import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NightLifeSidebar from "../layouts/NightLifeSidebar";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import { useNavigate } from "react-router-dom";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

function NewNightlifeOptions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleAnotherResto = async () => {
    setLoading(true);
    const resp = await webServices.createNightLife({
      profileLavel: AppConstants.routes.nightLife.category,
      progress: 0,
      userId: current?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      navigate(AppConstants.routes.nightLife.category);
    }
  };

  const handleDashboard = async () => {
    setLoading(true);
    const resp = await webServices.createNightLife({
      profileLavel: AppConstants.routes.admin.dashboard,
      progress: 100,
      nightLifeId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      navigate(AppConstants.routes.admin.dashboard);
    }
  };

  return (
    <>
      <div className="company-payment-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <NightLifeSidebar details={companyDetails} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Create Another Nightlife</div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                  Congratulations on completing the signup process for your first nightlife venue.
                  </div>
                  <div className="info-text">
                  If you run more than one venue, click the{" "}
                    <strong>
                      <i>"Add Another Venue"</i>
                    </strong>{" "}
                    button and complete the signup process.
                  </div>
                  <div className="info-text">
                    Once you’re done, click the{" "}
                    <strong>
                      <i>"Dashboard"</i>
                    </strong>{" "}
                    button to access your dashboard.
                  </div>

                  <div className="d-flex flex-wrap justify-content-center gap-2 gap-md-4 pt-4">
                    <button
                      type="submit"
                      className="btn btn-custom"
                      style={{ fontSize: 22 }}
                      onClick={handleAnotherResto}
                      disabled={loading || false}
                    >
                      {loading ? "Loading..." : "Add Another Venue"}
                    </button>

                    <button
                      type="submit"
                      className="btn btn-custom"
                      style={{ fontSize: 22 }}
                      onClick={handleDashboard}
                      disabled={loading || false}
                    >
                      {loading ? "Loading..." : "Dashboard"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewNightlifeOptions;
