import React from "react";
import { useNavigate } from "react-router-dom";

// Layoutes
import UserLayout from "./layouts/userLayoutes/UserLayout";
import GuestLayout from "./layouts/userLayoutes/GuestLayout";
import HomeLayout from "./layouts/homeLayout";

import HomePageComponent from "./HomePageComponent";
import TutorialComponent from "./TutorialComponent";
import AdvertiseLandingPage from "./advertisement/AdvertiseLandingPage";
import DinningLandingPage from "./restaurant/DinningLandingPage";
import NightlifeLandingPage from "./advertisement/NightlifeLandingPage";
import NightlifeHomePage from "./night_life/NightlifeHomePage";
import ContactPage from "./advertisement/ContactPage";
import Register from "./Register";
import HomeComponent from "./HomeComponent";
import MainHomeComponent from "./MainHomeComponent";
import SubPageListComponent from "./SubPageListComponent";
import SearchListComponent from "./SearchListComponent";
import CompanyType from "./CompanyType";
import Login from "./Login";
import PageNotFound from "./PageNotFound";

// Tour
import Tour from "./tour/Tour";
import BookingCheckout from "./tour/BookingCheckout";
import BookingSuccess from "./tour/BookingSuccess";
import TourBookingCalendarAction from "./tour/TourBookingCalendarAction";
import TourRezdyIntegrationAction from "./tour/TourRezdyIntegrationAction";
import TourCostPerPersonAction from "./tour/TourCostPerPersonAction";
import CompanyCategory from "./pages/activities/CompanyCategory";
import ActivityDetails from "./pages/activities/ActivityDetails";
import TourStopsAction from "./tour/TourStopsAction";
import TourLocationDetailsAction from "./tour/TourLocationDetailsAction";
import Payment from "./pages/activities/Payment";
import ActivityAction from "./pages/activities/ActivityAction";
import OnboardComplete from "./pages/activities/OnboardComplete";

// Restaurant
import DesiredServicesPage from "./Restaurants/DesiredServicesPage";
import RestaurantCategoryPage from "./Restaurants/RestaurantCategoryPage";
import RestaurantDetailsPage from "./Restaurants/RestaurantDetailsPage";
import RestaurantMenuPage from "./Restaurants/RestaurantMenuPage";
import RestaurantPaymentPage from "./Restaurants/RestaurantPaymentPage";
import NewRestaurantOptions from "./Restaurants/NewRestaurantOptions";

import Restaurant from "./restaurant/Restaurant";
import DesiredServices from "./restaurant/DesiredServices";
import RestaurantCategory from "./restaurant/RestaurantCategory";
import RestaurantDetails from "./restaurant/RestaurantDetails";
import RestaurantMenu from "./restaurant/RestaurantMenu";
import MenuPreview from "./restaurant/MenuPreview";
import RestaurantPayment from "./restaurant/RestaurantPayment";
import RestaurantAction from "./restaurant/RestaurantAction";
import DinningHomePage from "./restaurant/DinningHomePage";

// NightLife
import Category from "./night_life/Category";
import VenueDetails from "./night_life/VenueDetails";
import EventDetails from "./night_life/EventDetails";
import NightlifePayment from "./night_life/NightlifePayment";
import NightLife from "./night_life/NightLife";
import NightLifeEventDetails from "./night_life/NightLifeEventDetails";
import NewNightlifeOptions from "./night_life/NewNightlifeOptions";

//Admin
import DashboardAction from "./admin/DashboardAction";
import UpdateActivityPrice from "./admin/UpdateActivityPrice";
import Settings from "./admin/Settings";
import UpdateDetails from "./admin/UpdateDetails";
import ChangeEmail from "./admin/ChangeEmail";
import ChangePassword from "./admin/ChangePassword";
import RunAnAdd from "./admin/ads/RunAnAdd";
import AdsList from "./admin/ads/AdsList";
import AdsReports from "./admin/ads/AdsReports";
import SelectScreen from "./admin/ads/SelectScreen";
import UploadContent from "./admin/UploadContent";
import AdsSubscriptionPayment from "./tour/BookingCheckout";
import PlanDetailPage from "./admin/PlanDetailPage";
import MachineAdd from "./admin/MachineAdd";
import MachineList from "./admin/MachineList";

// CHECKINGS
import EditListing from "./admin/checking/EditListing";
import TagsList from "./admin/checking/TagsList";
import NightlifeEventListing from "./admin/checking/NightlifeEventListing";

// NIGHTLIFE ADMIN USER
import NighlifeEditListing from "./admin/nightLife/NighlifeEditListing";
import UpdateNighlifeCategory from "./admin/nightLife/UpdateNighlifeCategory";
import UpdateNightlifeVenueDetails from "./admin/nightLife/UpdateNightlifeVenueDetails";
import EventsAdd from "./admin/events/EventsAdd";
import EventsList from "./admin/events/EventsList";
import UserListing from "./admin/users/UserListing";
import UserAdd from "./admin/users/UserAdd";

/* activity */
import UpdateAdminCostPerson from "./admin/checking/activity/UpdateAdminCostPerson";
import UpdateAdminCompanyDetails from "./admin/checking/activity/UpdateAdminCompanyDetails";
import UpdateAdminStops from "./admin/checking/activity/UpdateAdminStops";
import UpdateAdminLocationDetails from "./admin/checking/activity/UpdateAdminLocationDetails";
import UpdateAdminCompanyCategory from "./admin/checking/activity/UpdateAdminCompanyCategory";

// END CHECKINGS

// DESIGNER CONTENTS
import ContentsAdsList from "./admin/designer_contents/ContentsAdsList";

// update restaurant
import RestaurantEditListing from "./admin/restaurant/RestaurantEditListing";
import UpdateRestaurantDetails from "./admin/restaurant/UpdateRestaurantDetails";
import UpdateRestaurantCategory from "./admin/restaurant/UpdateRestaurantCategory";
import UpdateRestaurantMenu from "./admin/restaurant/UpdateRestaurantMenu";
import UpdateDesireServices from "./admin/restaurant/UpdateDesireServices";
import MenuListing from "./admin/restaurant/MenuListing";
import UpdateMenuDetails from "./admin/restaurant/UpdateMenuDetails";

// night life
import UpdateVenueDetails from "./admin/nightLife/UpdateVenueDetails";
import EventListing from "./admin/nightLife/EventListing";
import UpdateEventDetails from "./admin/nightLife/UpdateEventDetails";

// update activites
import ActivityEditListing from "./admin/activities/ActivityEditListing";
import ActivityCompanyType from "./admin/activities/ActivityCompanyType";
import ActivityAdminAction from "./admin/activities/ActivityAdminAction";
import UpdateCostPerson from "./admin/activities/UpdateCostPerson";
import UpdateCompanyDetails from "./admin/activities/UpdateCompanyDetails";
import UpdateStops from "./admin/activities/UpdateStops";
import UpdateLocationDetails from "./admin/activities/UpdateLocationDetails";

import StripeCheckout from "./StripeCheckout";
import SubscriptionCheckout from "./SubscriptionCheckout";
import StripeCustomCheckout from "./StripeCustomCheckout";
import BudgetRunAnAds from "./admin/BudgetRunAnAds";

// ####################### GUEST LAYOUT ######################
export const LoadingPageComponent = () => <HomePageComponent />;
export const TutorialPageComponent = () => <TutorialComponent />;
export const AdvertiseLandingPageComponent = () => <AdvertiseLandingPage />;
export const DinningLandingPageComponent = () => <DinningLandingPage />;
export const NightlifeLandingPageComponent = () => <NightlifeLandingPage />;
export const ContactPageComponent = () => <ContactPage />;

export const RegisterComponent = () => (
  <GuestLayout>
    <Register />
  </GuestLayout>
);

export const LoginComponent = () => (
  <GuestLayout>
    <Login />
  </GuestLayout>
);

// ####################### HOME LAYOUT ######################
export const HomeComponents = () => (
  <HomeLayout>
    <HomeComponent />
  </HomeLayout>
);

export const DinningHomePageComponents = () => (
  <HomeLayout>
    <DinningHomePage />
  </HomeLayout>
);

export const NightlifeHomePageComponents = () => (
  <HomeLayout>
    <NightlifeHomePage />
  </HomeLayout>
);

export const MainHomeComponents = () => (
  <HomeLayout>
    <MainHomeComponent />
  </HomeLayout>
);

export const SeachListComponents = () => (
  <HomeLayout>
    <SearchListComponent />
  </HomeLayout>
);

export const RestaurantDetailsPageComponents = () => (
  <HomeLayout>
    <Restaurant />
  </HomeLayout>
);

export const SubPageListComponents = () => (
  <HomeLayout>
    <SubPageListComponent />
  </HomeLayout>
);


export const TourComponent = () => {
  const navigate = useNavigate();
  return (
    <HomeLayout navigate={navigate}>
      <Tour navigate={navigate} />
    </HomeLayout>
  );
};

// ####################### USER LAYOUT ######################

export const RestaurantActionComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantAction navigate={navigate} />
    </UserLayout>
  );
};

export const ActivityActionComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <ActivityAction navigate={navigate} />
    </UserLayout>
  );
};

export const OnboardCompleteComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <OnboardComplete navigate={navigate} />
    </UserLayout>
  );
};

export const CompanyTypeComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <CompanyType navigate={navigate} />
    </UserLayout>
  );
};

export const StripeCheckoutComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <StripeCheckout navigate={navigate} />
    </UserLayout>
  );
};

export const SubscriptionCheckoutComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <SubscriptionCheckout navigate={navigate} />
    </UserLayout>
  );
};

export const SubscriptionCustomCheckoutComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <StripeCustomCheckout navigate={navigate} />
    </UserLayout>
  );
};

// TOUR

export const BookingCheckoutComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <BookingCheckout navigate={navigate} />
    </UserLayout>
  );
};

export const BookingSuccessComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <BookingSuccess navigate={navigate} />
    </UserLayout>
  );
};

export const TourBookingCalendarComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <TourBookingCalendarAction navigate={navigate} />
    </UserLayout>
  );
};

export const TourRezdyIntegrationComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <TourRezdyIntegrationAction navigate={navigate} />
    </UserLayout>
  );
};

export const TourCostPerPersonComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <TourCostPerPersonAction navigate={navigate} />
    </UserLayout>
  );
};

export const TourCompanyCategoryComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <CompanyCategory navigate={navigate} />
    </UserLayout>
  );
};

export const TourCompanyDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <ActivityDetails navigate={navigate} />
    </UserLayout>
  );
};

export const TourStopsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <TourStopsAction navigate={navigate} />
    </UserLayout>
  );
};

export const TourLocationDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <TourLocationDetailsAction navigate={navigate} />
    </UserLayout>
  );
};

export const TourPaymentComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <Payment navigate={navigate} />
    </UserLayout>
  );
};

//###################################### RESTAURANT ##########################################

export const DesiredServicesPageComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <DesiredServicesPage navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantCategoryPageComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantCategoryPage navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantDetailsPageComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantDetailsPage navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantMenuPageComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantMenuPage navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantPaymentPageComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantPaymentPage navigate={navigate} />
    </UserLayout>
  );
};

export const NewRestaurantOptionsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NewRestaurantOptions navigate={navigate} />
    </UserLayout>
  );
};


/////////////////////////////////////////////////////////
export const DesiredServicesComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <DesiredServices navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantCategoryComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantCategory navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantDetails navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantMenuComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantMenu navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantPaymentComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantPayment navigate={navigate} />
    </UserLayout>
  );
};

export const RestaurantComponent = () => (
  <UserLayout>
    <Restaurant />
  </UserLayout>
);

export const MenuPreviewComponent = () => (
  <UserLayout>
    <MenuPreview />
  </UserLayout>
);

// NIGHT LIFE
export const NightLifeCategoryComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <Category navigate={navigate} />
    </UserLayout>
  );
};

export const VenueDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <VenueDetails navigate={navigate} />
    </UserLayout>
  );
};

export const EventComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <EventDetails navigate={navigate} />
    </UserLayout>
  );
};

export const NightLifeComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NightLife navigate={navigate} />
    </UserLayout>
  );
};

export const EventDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NightLifeEventDetails navigate={navigate} />
    </UserLayout>
  );
};

export const NewNightlifeOptionComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NewNightlifeOptions navigate={navigate} />
    </UserLayout>
  );
};

export const NightlifePaymentComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NightlifePayment navigate={navigate} />
    </UserLayout>
  );
};

//################################# ADMIN #################################################

// RESTRAURANT - USER ADMIN 
export const RestaurantEditListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RestaurantEditListing navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateRestaurantDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateRestaurantDetails navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateRestaurantCategoryComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateRestaurantCategory navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateRestaurantMenuComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateRestaurantMenu navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateDesireServicesComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateDesireServices navigate={navigate} />
    </UserLayout>
  );
};
// END RESTRAURANT - USER ADMIN 


// NIGHTLIFE - USER ADMIN 
export const NightlifeEditListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NighlifeEditListing navigate={navigate} />
    </UserLayout>
  );
};

export const NightlifeUpdateCategoryComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateNighlifeCategory navigate={navigate} />
    </UserLayout>
  );
};

export const NightlifeUpdateVenueDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateNightlifeVenueDetails navigate={navigate} />
    </UserLayout>
  );
};

// Live Events
export const EventsAddComponent = () => (
  <UserLayout>
    <EventsAdd />
  </UserLayout>
);

export const EventsListComponent = () => (
  <UserLayout>
    <EventsList />
  </UserLayout>
);
// END NIGHTLIFE - USER ADMIN 

// CHECKINGS
export const EditListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <EditListing navigate={navigate} />
    </UserLayout>
  );
};

export const NightlifeEventListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <NightlifeEventListing navigate={navigate} />
    </UserLayout>
  );
};

export const TagsListComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <TagsList navigate={navigate} />
    </UserLayout>
  );
};

export const UserListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UserListing navigate={navigate} />
    </UserLayout>
  );
};

export const UserAddComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UserAdd navigate={navigate} />
    </UserLayout>
  );
};

// admin checking activity
export const UpdateAdminCostPersonComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateAdminCostPerson navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateAdminCompanyDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateAdminCompanyDetails navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateAdminStopsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateAdminStops navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateAdminLocationDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateAdminLocationDetails navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateAdminCompanyCategoryComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateAdminCompanyCategory navigate={navigate} />
    </UserLayout>
  );
};

export const ContentsAdsListComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <ContentsAdsList navigate={navigate} />
    </UserLayout>
  );
};

export const PlanComponent = () => (
  <UserLayout>
    <PlanDetailPage />
  </UserLayout>
);

export const DashboardComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <DashboardAction navigate={navigate} />
    </UserLayout>
  );
};

export const SettingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <Settings navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateActivityPriceComponent = () => (
  <UserLayout>
    <UpdateActivityPrice />
  </UserLayout>
);

export const UpdateDetailsComponent = () => (
  <UserLayout>
    <UpdateDetails />
  </UserLayout>
);

export const ChangeEmailComponent = () => (
  <UserLayout>
    <ChangeEmail />
  </UserLayout>
);

export const ChangePasswordComponent = () => (
  <UserLayout>
    <ChangePassword />
  </UserLayout>
);

export const RunAnAddComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <RunAnAdd navigate={navigate} />
    </UserLayout>
  );
};

export const ListAdsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <AdsList navigate={navigate} />
    </UserLayout>
  );
};

export const AdsReportsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <AdsReports navigate={navigate} />
    </UserLayout>
  );
};

export const BudgetRunAnAdsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <BudgetRunAnAds navigate={navigate} />
    </UserLayout>
  );
};

export const SelectScreenComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <SelectScreen navigate={navigate} />
    </UserLayout>
  );
};

export const UploadContentComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UploadContent navigate={navigate} />
    </UserLayout>
  );
};

export const AdsSubscriptionPaymentComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <AdsSubscriptionPayment navigate={navigate} />
    </UserLayout>
  );
};

// Edit Listing
export const MenuListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <MenuListing navigate={navigate} />
    </UserLayout>
  );
};



export const UpdateMenuDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateMenuDetails navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateVenueDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateVenueDetails navigate={navigate} />
    </UserLayout>
  );
};

export const EventListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <EventListing navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateEventDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateEventDetails navigate={navigate} />
    </UserLayout>
  );
};

// Admin update Activityes
export const UpdateCostPersonComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateCostPerson navigate={navigate} />
    </UserLayout>
  );
};

export const ActivityEditListingComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <ActivityEditListing navigate={navigate} />
    </UserLayout>
  );
};

export const ActivityCompanyTypeComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <ActivityCompanyType navigate={navigate} />
    </UserLayout>
  );
};
export const ActivityAdminActionComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <ActivityAdminAction navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateCompanyDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateCompanyDetails navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateStopsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateStops navigate={navigate} />
    </UserLayout>
  );
};

export const UpdateLocationDetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <UpdateLocationDetails navigate={navigate} />
    </UserLayout>
  );
};

export const MachineAddComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <MachineAdd navigate={navigate} />
    </UserLayout>
  );
};

export const MachineListComponent = () => {
  const navigate = useNavigate();
  return (
    <UserLayout navigate={navigate}>
      <MachineList navigate={navigate} />
    </UserLayout>
  );
};

export const PageNotFoundComponent = () => (
  <GuestLayout>
    <PageNotFound />
  </GuestLayout>
);