import React, { useState } from "react";
import NightLifeSidebar from "../layouts/NightLifeSidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import NightLifeEventDetailsComp from "../common/NightLifeEventDetailsComp";

const buttons = [
  {
    id: 1,
    type: "another",
    label: "Add Another Event",
  },
  {
    id: 2,
    type: "submit",
    label: "Submit",
  },
];

function EventDetails() {
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmitForm = async (data, act) => {
    setLoading(true);
    const resp = await webServices.createEvents({
      ...data,
      nightLifeId: companyDetails?.id,
      userId: current?.id
    });
    if (resp?.type === "success") {
      setLoading(false);
      const resp2 = await webServices.createNightLife({
        profileLavel:
          companyDetails?.progress < 75
            ? AppConstants.routes.nightLife.eventDetails
            : "",
        progress: companyDetails?.progress < 75 ? 75 : "",
        nightLifeId: companyDetails?.id,
      });
      dispatch(setCompanyDetails(resp2?.payload));
      if (act.type === "submit") {
        navigate(AppConstants.routes.nightLife.newNightlifeOption);
      }
    }
  };

  return (
    <>
      <NightLifeEventDetailsComp
        onSubmit={handleSubmitForm}
        loading={loading}
        venueDetails={companyDetails}
        heading="Please Enter Your Event Details:"
        Sidebar={NightLifeSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default EventDetails;
